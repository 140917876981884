import React from 'react'
import './NavBar.css'
import Tutor from '../../Images/tutor.png'
import cross from '../../Images/cros.png'
import bar from '../../Images/barr.png'
import { Link } from "react-router-dom";
import { UserContext } from '../../UserProvider'
import monitor from "../../Images/monitor.png";


const NavBar = () => {

  const { user, logout, BaseUrl, AppUrl } = React.useContext(UserContext);

  React.useEffect(() => {
    window.scrollTo(0, 0)
    return () => {
      document.body.style = '';
    };
  }, []);

  return (
    <header className='web'>
      <div className="navb-logo">
        <img src={Tutor} alt="Logo" />
      </div>
      <div className='centr position-relative gap-5'>

        <img src={monitor} alt="monitor" className='cen monitor' style={{ height: "200px", display: 'none' }}></img>

        <div className="item" >
          <Link to="/" style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'none' }}>Home</Link>
        </div>
        {('token' in user) &&
          <div className="dropdown">
            <button
              className="btn border-0 item dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'none' }}
            >
              Dashboard
            </button>
            <ul className="dropdown-menu">
              <li>
                <Link className="dropdown-item" to="/Tutor">
                  Tutor
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/Student">
                  Student
                </Link>
              </li>
            </ul>
          </div>
        }
        <div className="item" >
          <Link to="/AboutUs" style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'none' }}>About us</Link>
        </div>

         {/* // */}
           <div className="item" >
          <Link to="/Howitworks" style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'none' }}>How it works</Link>
        </div>
         {/* // */}


        <div className="item" >
          <Link to="/Contact" style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'none' }}>Contact us</Link>
        </div>

      </div>
      <div className='second-div'>
        {!('token' in user) &&
          <>
            <div className="item mx-2"  >
              <Link to="/Login" style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'none' }}>
                <div style={{ display: 'flex' }} ><i className="fa fa-user pt-1 mx-1" style={{ fontSize: '20px' }}></i>
                  <span style={{ fontFamily: 'Poppins' }}>Login</span>
                </div>
              </Link>
            </div>

            <div className="item-button ms-2" >
              <Link to="/Register" className='bg-transparent text-decoration-none'>
                <button className='but px-4 py-2' type="button" style={{ fontFamily: 'Poppins' }}>Register</button>
              </Link>
            </div>
          </>
        }

        {('token' in user) &&
          <>
            {/* <div className="item mx-2"  >
                  <Link to="/Login" style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'none' }}>
                    <div style={{ display: 'flex' }} ><i className="fa fa-user pt-1 mx-1" style={{ fontSize: '20px' }}></i>
                      <span style={{ fontFamily: 'Poppins' }}>Dashboard</span>
                    </div>
                  </Link>
                </div> */}

            <div className="item-button mx-2" >
              <button className='but px-4 py-2' type="button" onClick={logout} style={{ fontFamily: 'Poppins' }}>Logout</button>
            </div>
          </>
        }
      </div>



      <div className="canva" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
        <img src={bar} alt='' style={{ width: '40px', height: '40px' }}></img>
      </div>

      <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header">

          <button type="button" className='ms-auto' id='Navbar_off_close' data-bs-dismiss="offcanvas" aria-label="Close" style={{ backgroundColor: 'transparent', border: 'none' }}> <img src={cross} alt='' style={{ width: '30px', height: '30px', position: 'relative' }}></img></button>
        </div>
        <div className="offcanvas-body">
          <div className='text-center' >
            <div className="line pt-4">
              <Link to="/" style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'none', fontSize: '30px' }}>Home</Link></div>
            <div className="line pt-5">
              <Link to="/AboutUs" style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'none', fontSize: '30px' }} >About us</Link>
            </div>

            <div className="line pt-5">
              <Link to="/Howitworks" style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'none', fontSize: '30px' }}  >How It Works</Link>
            </div>

            <div className="line pt-5">
              <Link to="/Contact" style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'none', fontSize: '30px' }}  >Contact us</Link>
            </div>

          
            {!('token' in user) &&
              <>
                <div className="line mt-5">
                  <Link className='btn border-0' to="/Login" style={{ color: 'white', textDecoration: 'none', fontSize: '30px' }}><i className="fa fa-user px-1"></i><span style={{ fontFamily: 'Poppins' }}>Login</span></Link>
                </div>

                <div className="line mt-5">
                  <Link className='but px-4 py-2 btn border-0 hover' to="/Register" type="button" style={{ fontFamily: 'Poppins' }}>Register</Link>
                </div>
              </>
            }
            {('token' in user) &&
              <>
                {/* <div className="item mx-2"  >
                  <Link to="/Login" style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'none' }}>
                    <div style={{ display: 'flex' }} ><i className="fa fa-user pt-1 mx-1" style={{ fontSize: '20px' }}></i>
                      <span style={{ fontFamily: 'Poppins' }}>Dashboard</span>
                    </div>
                  </Link>
                </div> */}

                <div className="item-button mx-2 mt-5" >
                  <button className='but px-4 py-2' type="button" onClick={logout} style={{ fontFamily: 'Poppins' }}>Logout</button>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </header>
  )
}

export default NavBar