import './header.css';
import React from 'react'
import { memo } from 'react';
import { Link } from 'react-router-dom';
import userIcon from '../asset/user-icon.png';
// import walletIcon from '../asset/wallet-icon.svg';
import logoutIcon from '../asset/logout-icon.png';
import logo from '../asset/tutor-dash.png';
import { UserContext } from '../../UserProvider';

const Header = ({ lock }) => {

    // const profileMenu = (e) => {
    //     const element = e.currentTarget;
    //     element.children[1].classList.toggle('show')
    // }

    const { user, logout, AppUrl } = React.useContext(UserContext);


    return (
        <header className='bg-white'>
            <div className='container-xl d-flex justify-content-between align-items-center px-xl-0 px-3 py-2'>
                <div className='logo'>
                    <img src={logo} height="95px" width="auto" alt='logo' />
                </div>
                <nav className='d-flex align-items-center gap-2'>
                    {!lock &&
                        <div className='noti'>
                            <Link type='button' to={`${AppUrl}/student/message/?token=${user.token}`} target="_blank" className='btn rounded-circle p-3 text-muted border-0'>
                                <span className="material-icons fw-lighter">
                                    message
                                </span>
                            </Link>
                        </div>
                    }
                    <div className="dropdown position-static" onClick={(e) => {
                        const element = e.currentTarget;
                        const rect = element.getBoundingClientRect();
                        element.children[1].style.transform = `translate(${rect.left + 10}px , ${rect.top + 55}px)`;
                    }}>
                        <div type="button" data-bs-toggle="dropdown" aria-expanded="true" >
                            <div className='profile d-flex align-items-center btn border-0 p-2 gap-3'>
                                <div className="teachImageDiv"><img src={user?.profile} className="rounded-5" height="50px" width="50px" alt="profile-img" /></div>
                                <div className='d-flex align-items-center'>
                                    <h5 className='mb-0'>{user?.first_name}</h5>
                                </div>
                                <span className="p-2 material-icons fw-bolder">
                                    arrow_drop_down
                                </span>
                            </div>
                        </div>
                        <ul className="dropdown-menu" style={{
                            background: 'rgb(245 245 245)',
                            border: '1px solid rgb(231 231 231)'
                        }} >
                            {!lock &&
                                <>
                                    <li>
                                        <Link to='/Student/Profile' className="dropdown-item py-2 my-1 d-flex align-items-center gap-2">
                                            <img src={userIcon} alt="icon" height="22px" width="22px" />
                                            Profile Setting
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/Student/Wallet' className="dropdown-item py-2 my-1 justify-content-start align-items-center">
                                            <span className="material-icons p-0 m-0 pe-2">
                                                wallet
                                            </span>
                                            Wallet
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/Tutor' className="dropdown-item py-2 my-1 d-flex align-items-center gap-2">
                                            <img src={userIcon} alt="icon" height="22px" width="22px" />
                                            Switch To Tutor
                                        </Link>
                                    </li>
                                </>
                            }
                            <li>
                                <button className="dropdown-item py-2 my-1 d-flex align-items-center gap-2" onClick={logout}>
                                    <img src={logoutIcon} alt="icon" height="20px" width="20px" />
                                    Logout
                                </button>
                            </li>
                        </ul>
                    </div>

                </nav>
            </div>
        </header>
    )
}

export default memo(Header)
