import axios from 'axios';
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { UserContext } from '../UserProvider';
import Loading from '../loading';

const Verify = () => {

    const param = useParams();
    const { BaseUrl } = React.useContext(UserContext);
    const [message, setMsg] = React.useState('');
    const [Onbusy, setOnbusy] = React.useState(true);


    const verify_email = async () => {

        try {
            const { data } = await axios.get(`${BaseUrl}/user/verify/${param.token}`);

            if (data?.success) {
                setMsg('Email verification successful. Now you can close the tab.')
            }
            else {
                setMsg('Somethings wents wrong')
            }

        } catch (error) {
            setMsg('Somethings wents wrong')
        }

        setOnbusy(false)
    }

    useEffect(() => {
        verify_email();
    }, [])


    if (Onbusy) {
        return <Loading display="Please wait" />
    }

    return (
        <div className='d-flex vh-100 justify-content-center align-items-center'>
            <div>{message}</div>
        </div>
    )
}

export default Verify;