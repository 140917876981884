import React from 'react'
import './About.css'
import one from '../../Images/one.png';
import two from '../../Images/two.png';
import three from '../../Images/three.png';
import four from '../../Images/four.png';
const About = () => {
  return (
    <div>
      <div className='container-xxl' style={{ marginTop : '90px'}}>
        <div className='d-flex flex-wrap-reverse gap-3' style={{ paddingTop: '60px' }}>
          <div className='col' >

            <h3 className=' mb-4' style={{ fontWeight:'600' ,fontFamily: 'Poppins', fontSize: '30px' }}>Mission: </h3>
            <p className='paras' style={{  fontFamily: 'Poppins', fontSize: '18px', letterSpacing: '0px', color: 'rgba(0, 0, 0, 0.5)', fontWeight: 'normal' }}>
       To provide people with a platform they need to share and receive information in a way that will make the learning experience more accessible and easier.
            </p>

            <h3 className='= mb-4 my-5' style={{ fontWeight:'600' , fontFamily: 'Poppins' , fontSize: '30px'}}>Vision: </h3>
            <p className='paras' style={{ fontFamily: 'Poppins' , fontSize: '18px', letterSpacing: '0px'}}>
       Our vision is to simplify education and make it accessible at the touch of your fingertips.
Tutors’ Community aims to provide:
            </p>
            <ul style={{padding: '0'}}>
              <li style={{color:'rgba(0,0,0,.78)',fontWeight:300}} >Tutors with a teaching environment where they can meet with learners, without the hassle of having to advertise themselves. The students/learners find them when they need them.</li>
              <li style={{color:'rgba(0,0,0,.78);',fontWeight:300}} className='mt-3'>Students with study challenges with a solution that is safe, effective, and affordable, in their chosen timeframes</li>
            </ul>
            {/* <button className="learnMoreBtn" style={{ fontFamily: 'Poppins' }}>Learn more</button> */}

          </div>
          <div className='col-12 col-lg-4 d-none d-lg-block'>
            <div className='img-one m-auto' style={{height: '400px'}}>
              <img src={one} alt='' height="100%" width="100%" ></img>
              {/* <img src={two} alt='' className='two-img'></img> */}
            </div>
            {/* <div className='img-two pt-4'> */}
              {/* <img src={three} alt='' className='three-img'></img> */}
              {/* <img src={four} alt='' className='four-img'></img> */}
            {/* </div> */}
          </div>
        </div>










      </div>




    </div>
  )
}

export default About