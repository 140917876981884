import React from "react";
import "./Home.css";
import pencil from "../../Images/pencil.png";
import pen from "../../Images/pen.png";
import book from "../../Images/book.png";
import cup from "../../Images/cup.png";
import NavBar from "../NavBar/NavBar";
import About from "../About/About";
// import Institute from "../Institute/Institute";
import Community from "../Community/Community";
import Testimonals from "../Testimonal/Testimonals";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import { UserContext } from '../../UserProvider'

const Home = () => {
  const { user } = React.useContext(UserContext);
  return (
    <div className="">
      <div className="home">
        <section className="container-xxl">
        
          <NavBar />
          <div className="con-cen text-center w-100">
            <div className="head-one mt-sm-5" style={{ fontFamily: 'Poppins' }}>
              Need Tutoring Assistance Remotely?
            </div>
            <div className="head-two m-auto pt-3" style={{ fontFamily: 'Poppins' , maxWidth : '800px' }}>
              Get Tutoring assistance remotely at your convenient time
            </div>
            <div className="mt-5 row">
              <div className="col-1 col-md-3"></div>
                <div className="col-10 col-md-8">
                  <div className="row">
                    <div className="col-md-6 col-sm-12" style={{color:'white',textAlign:'center'}}>
                      <h5>Experience the freedom of Pay-Per-Minute flexibility at just R5 per minute.</h5>
                    </div>
                    <div className="col-md-4 col-sm-12 d-none d-md-block d-lg-block d-sm-none">
                      {!('token' in user) &&
                        <>
                          <Link to="/Register#Student" className="btn btn-lg btn-primary" style={{backgroundColor:'#FAB24E'}}>&nbsp;&nbsp;&nbsp;&nbsp;JOIN NOW&nbsp;&nbsp;&nbsp;&nbsp;</Link>
                        </>
                      }
                      {('token' in user) &&
                        <Link to="Student" className="btn btn-lg btn-primary" style={{backgroundColor:'#FAB24E'}}>&nbsp;&nbsp; DASHBOARD &nbsp;&nbsp;</Link>
                      }
                      
                    </div>
                  </div>
                </div>
              <div className="col-1 col-md-5"></div>
            </div>
            <div className="mt-5 row">
              <div className="col-1 col-md-3"></div>
                <div className="col-10 col-md-8">
                  <div className="row">
                    <div className="col-md-6 col-sm-12" style={{color:'white',textAlign:'center'}}>
                      <h5>Gain immediate access to a pool of highly qualified and approved tutors.</h5>
                    </div>
                    <div className="col-md-4 col-sm-12 d-none d-md-block d-lg-block d-sm-none">
                      <Link to="/Student/RequestForAdmin" className="btn btn-lg btn-primary" style={{backgroundColor:'#FAB24E'}}>BOOK A TUTOR</Link>
                    </div>
                  </div>
                </div>
              <div className="col-1 col-md-5"></div>
            </div>
            <div className="mt-5 row">
              <div className="col-1 col-md-3"></div>
                <div className="col-10 col-md-8">
                  <div className="row">
                    <div className="col-md-6 col-sm-12" style={{color:'white',textAlign:'center'}}>
                      <h5>Recieve Tutoring proposals from a diverse selection of tutors to suit your needs.</h5>
                    </div>
                    <div className="col-md-4 col-sm-12 d-none d-md-block d-lg-block d-sm-none">
                    {!('token' in user) &&
                        <>
                          <Link to="/Register#Tutor" className="btn btn-lg btn-primary" href="" style={{backgroundColor:'#FAB24E'}}>JOIN AS TUTOR</Link>
                        </>
                    }
                     {('token' in user) && 
                     <>
                        <Link to="Tutor" className="btn btn-lg btn-primary" style={{backgroundColor:'#FAB24E'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; TUTOR &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Link>
                     </>}
                    </div>
                  </div>
                </div>
              <div className="col-1 col-md-5"></div>
            </div>
            <div class="row justify-content-center d-block d-flex d-lg-none d-md-none d-sm-flex">
              <div class="col-10 px-2 mt-5">
                {!('token' in user) &&
                  <>
                    <Link to="Student/Register" className="btn btn-lg btn-primary" style={{backgroundColor:'#FAB24E',width:'100%'}}>&nbsp;&nbsp;&nbsp;&nbsp;JOIN NOW&nbsp;&nbsp;&nbsp;&nbsp;</Link>
                  </>
                }
                {('token' in user) &&
                        <Link to="Student" className="btn btn-lg btn-primary" style={{backgroundColor:'#FAB24E',width:'100%'}}>&nbsp;&nbsp; DASHBOARD &nbsp;&nbsp;</Link>
                }
              </div>
              <div class="col-10 px-2 mt-5">
              <Link to="/Student/RequestForAdmin" className="btn btn-lg btn-primary" style={{backgroundColor:'#FAB24E',width:'100%'}}>BOOK A TUTOR</Link>
              </div>
              <div class="col-10 px-2 mt-5">
              {!('token' in user) &&
                        <>
                          <Link to="/Tutor/Register" className="btn btn-lg btn-primary" href="" style={{backgroundColor:'#FAB24E',width:'100%'}}>JOIN AS TUTOR</Link>
                        </>
                    }
                     {('token' in user) && 
                     <>
<Link to="Tutor" className="btn btn-lg btn-primary" style={{backgroundColor:'#FAB24E',width:'100%'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; TUTOR &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Link>
                     </>}
              </div>
            </div>
          </div>
          <div className="pencil">
            <img src={pencil} alt="pencil" style={{ height: "500px" }} />
          </div>
          <div className="pen">
            <img src={pen} alt="pen" style={{ height: "300px", width: "300px" }} />
          </div>
          <div className="book">
            <img src={book} alt="book" className="boo" />
          </div>
          <div className="cup">
            <img src={cup} alt="cup" style={{ height: "300px" }} />
          </div>
        </section>
      </div>

      <About />
      <Community />
      {/* <Testimonals /> */}
      <Footer />
    </div>
  );
};

export default Home;