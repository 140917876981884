import React from 'react'
import './Tutors.css'
import Book from '../../Images/about01.jpg'
import BookOne from '../../Images/about02.jpg'

const Tutors = () => {
  return (
    <div className=' container-xxl'>
      <div className='fluid-institute' style={{ paddingBottom: '50px' }}>
        <div className='text-center pt-5'>
          <h1 style={{ fontFamily: 'Poppins' }}>Tutors Community</h1>
        </div>
        <div className='row mt-5 roww'>
          <div className='col-12 col-md-6' >
            <p className='par'>
            Tutors' Community is a body of teachers, lecturers and subject specialists who have made their expertise available to help learners and students with problematic modules/subjects. It is a platform where tutors offer students/learners guidance and support in modules/subjects where tutors are either experts or have excelled, to make their study life easy. The objective of this platform is to elevate students/learners' confidence in their studies and to improve their marks particularly in those modules/subjects where they struggle.
            </p>

          </div>
          <div className='col-12 col-md-6' >
            <div className='des' >
              <img src={Book} alt='' className='book-one rounded-5 img-fluid'></img>
            </div>
          </div>

        </div>


        {/*  */}
        <div className='row mt-5 pt-5 rowss'>
          <div className='col-12 col-md-6' >
            <div className='desk' >
              <img src={BookOne} alt='' className='book-one rounded-5 img-fluid'></img>
            </div>


          </div>
          <div className='col-12 col-md-6' >
            <p className='par'>
       Tutors' Community was established in 2021. The idea is to gather as many subject experts who have a passion for teaching in one platform, and give access to a wealth of knowledge to students/learners who find roadblocks in certain areas of subjects/modules they are enrolled in. In this platform, tutors will use their knowledge and study mechanisms to guide students/learners to navigate their studies to the desired end, which is better understanding of the subject/module and passing with higher marks.
            </p>
          </div>

        </div>
        {/*  */}






      </div>



    </div>
  )
}

export default Tutors