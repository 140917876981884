import React from 'react'
import { UserContext } from '../UserProvider';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const Cashout = () => {

    const [banks, setbanks] = React.useState([]);
    const { user, logout, BaseUrl } = React.useContext(UserContext);
    const [busy, setBusy] = React.useState(true);
    const [error, setError] = React.useState('');
    const button = React.useRef();
    const select = React.useRef();
    const param = useParams();
    const navigate = useNavigate();

    const FETCH_USER_BANKS = async () => {
        try {
            const { data, status } = await axios.get(`${BaseUrl}/tutor/bank/detail`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            if (data.success && status === 200) {
                setbanks(data.data);
            }

            if (status === 401) {
                logout();
            }

        } catch (error) {
            if (error.response.status === 401) {
                logout();
            }
            console.log(error)
        }

        setBusy(false);
    }

    React.useEffect(() => {
        FETCH_USER_BANKS();
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();

        button.current.disabled = true;
        const account_id = select.current.value;

        try {
            const { data, status } = await axios.post(`${BaseUrl}/tutor/wallet/cashout`, {
                account_id: account_id,
                sessionToken: param.data
            }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            if (data?.success && status === 200) {
                navigate('/Tutor/Earnings')
                button.current.disabled = false;
                return
            }

            if (status === 401) {
                logout();
            }

        } catch (error) {
            if (error?.response?.status === 401) {
                logout();
            }
            console.log(error)
        }
        setError('Somethings wents wrong')
        button.current.disabled = false;
    }

    return (

        <section className='Earning py-5 user-select-none'>
            <div className='card container-lg pb-3'>
                <div className='card-header bg-white py-3 py-lg-4 px-4'>
                    <h6 className='card-title mb-0 fw-medium'>Cashout</h6>
                </div>
                <div className='card-body'>
                    <form onSubmit={handleSubmit}>
                        <select className='form-select' disabled={busy && 'disabled'} required ref={select}>
                            {!busy && banks?.length === 0 &&
                                <option value="">No Account Found</option>
                            }
                            {!busy && banks?.length > 0 &&
                                <option value="">Select Account</option>
                            }
                            {!busy && banks.map((bank, index) => (
                                <option value={bank?.id} key={index}>{bank?.bank_name} {bank?.account_no}</option>
                            ))}
                        </select>
                        <div className='text-danger'>{error}</div>
                        <div className='text-end mt-4'>
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => navigate('/Tutor/Earnings')}
                            >
                                Cancel
                            </button>
                            <button type="submit" ref={button} className="btn btn-primary">
                                Cashout
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section >
    )
}

export default Cashout
