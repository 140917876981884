import React, { useState } from 'react';
import background from './asset/login.png';
import user from './asset/user.png';
import './css/login.css';
import axios from 'axios';
import { UserContext } from '../UserProvider';
const ForgetPassword = () => {
    const {BaseUrl } = React.useContext(UserContext);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

   const handleResetPassword = async (e) => {
    e.preventDefault();
    
    try {
       // const BaseUrl = 'http://127.0.0.1:8000/api';
        const { data, status } = await axios.post(`${BaseUrl}/forgetpassword`, {
            email: email,
        });

        if (status === 200) {
            setMessage(`Success: ${data.msg}`);
            console.log('API response:', data);
        } else {
            setMessage(`Error: ${data.msg}`);
            console.error('API error:', data);
        }
    } catch (error) {
        console.error('Error:', error.message);
        setMessage(`Error: ${error.message}`);
    }
};

    return (
        <main className='d-flex vh-100 login m-auto' style={{ maxWidth: '2000px' }}>
            <div className='d-none d-lg-block col h-100' style={{ background: `url(${background})`, backgroundSize: 'cover' }}>
                <div className='d-flex h-75 justify-content-center flex-column align-items-center'>
                    <h5 className='fs-5 fw-normal mb-3'>Looks like you are new here!</h5>
                    <h3>Join one of the fastest-growing</h3>
                    <h3>Tutors Community in the world</h3>
                </div>
            </div>
            <div className='col-12 col-lg-7 bg-white px-3 px-sm-5 position-relative'>
                <div className='d-flex flex-column justify-content-center h-100 gap-2'>
                    <h6>Reset Your Password</h6>
                    <h4>Tutors Community</h4>
                    <form className='d-flex flex-column mt-4 gap-4 col-12 col-lg-10 col-xl-9' onSubmit={handleResetPassword}>
                        <div className='border-bottom d-flex align-items-center pb-1'>
                            <label htmlFor='email'>
                                <img src={user} height='16px' width='auto' alt='email' />
                            </label>
                            <input
                                className='border-0 form-control'
                                name='email'
                                placeholder='Email'
                                type='email'
                                id='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <button className='btn btn-primary rounded-4' type='submit' style={{ width: '160px' }}>
                            Reset
                        </button>
                    </form>
                    {message && (
                        <p className={message.includes('Error') ? 'text-danger' : 'text-success'}>{message}</p>
                    )}
                </div>
            </div>
        </main>
    );
};

export default ForgetPassword;
