import React from 'react';
import ReactDOM from 'react-dom/client';
import UserProvider from './UserProvider';
import RoutesHandler from './Routes';
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
  <UserProvider>
    <RoutesHandler />
  </UserProvider>
  //</React.StrictMode> 
);
