import axios from 'axios';
import React from 'react'
import { UserContext } from '../UserProvider';
import sub_icon from './asset/check-sb-icon.svg';
import './css/checkout.css';
import { useParams, useNavigate, Link } from 'react-router-dom';



const CheckoutCard = ({ jobSession, setError }) => {

    const { user, BaseUrl, logout } = React.useContext(UserContext);
    const navigate = useNavigate();


    const Paynow = async (e) => {

        const button = e.currentTarget;
        button.disabled = true;

        try {
            const { data, status } = await axios.post(`${BaseUrl}/student/wallet/paynow`, {token: jobSession.token }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            if (data?.success && status === 200) {
                navigate(`/Student/Sessions`);
            }

            if (!data?.success && data?.code === 'insufficient_balance') {
                setError('funds');
            }

            if (status === 401) {
                logout();
            }

        } catch (error) {
            if (error?.response?.status === 401) {
                logout();
            }
            console.log(error)
        }

        button.disabled = false;
    }

    return (
        <div className='py-2'>
            <div className='table-responsive'>
                <table className="table">
                    <tbody>
                        <tr>
                            <td colSpan={7}>
                                <h6>Job Details</h6>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className='d-flex align-items-center gap-2'>
                                    <img src={sub_icon} width="30px" height="30px" alt='icon' />
                                    <div>
                                        <h6 className="mb-0">Subject</h6>
                                        <p className='m-0'>{jobSession?.job?.subject?.name}</p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <i className="material-icons">trending_flat</i>
                            </td>
                            <td>
                                <h6 className="m-0">Minutes</h6>
                                <p className='m-0'>{jobSession.job?.mintues}</p>
                            </td>
                            <td>
                                <i className="material-icons">trending_flat</i>
                            </td>
                            <td>
                                <h6 className="m-0">{jobSession?.job?.title}</h6>
                                <p className='m-0'>{jobSession?.job?.description}</p>
                            </td>
                            <td>
                                <i className="material-icons">trending_flat</i>
                            </td>
                            <td>
                                <h6 className="m-0">Post on</h6>
                                <p className='m-0'>{jobSession?.job?.created_at}</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={7}>
                                <h6>Payment Details</h6>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="d-flex align-items-center gap-3">
                                    <img
                                        src={user?.profile}
                                        height="50px"
                                        width="50px"
                                        alt='user-profile'
                                        style={{ borderRadius: 50 }}
                                    />
                                    <div>
                                        <h6 className="m-0">{user?.first_name}</h6>
                                        <p className='m-0'>{user?.email}</p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <i className="material-icons">trending_flat</i>
                            </td>
                            <td>
                                <h6 className="m-0">Price</h6>
                                <p className='m-0'>R {jobSession?.job?.price}</p>
                            </td>
                            <td>
                                <i className="material-icons">trending_flat</i>
                            </td>
                            <td>
                                <div className="d-flex align-items-center gap-3">
                                    <img
                                        src={jobSession?.user_tutor?.profile}
                                        height="50px"
                                        width="50px"
                                        style={{ borderRadius: 50 }}
                                        alt="user-profile"
                                    />
                                    <div>
                                        <h6 className="m-0" >{jobSession?.user_tutor?.first_name}</h6>
                                        <p className='m-0'>{jobSession?.user_tutor?.email}</p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <i className="material-icons">trending_flat</i>
                            </td>
                            <td>
                                <h6 className="m-0">Session ID</h6>
                                <p className='m-0'>{jobSession?.token}</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={7}>
                                <h6>Payment Method</h6>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={7}>
                                <div className='d-flex'>
                                    <div className="payment_box_">
                                        <input
                                            type="radio"
                                            checked={true}
                                            readOnly={true}
                                            data="wallet"
                                            id="wallet_option"
                                            name="method"
                                        />
                                        <img
                                            src="https://static.vecteezy.com/system/resources/previews/004/431/001/original/wallet-with-money-on-a-white-background-free-vector.jpg"
                                            height="33px" alt='wallet-icon'
                                        />
                                        WALLET
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className='d-flex justify-content-end gap-2 w-100'>
                    <Link type='button' to="/Student/Proposals" className='btn btn-primary'>Cancel</Link>
                    <button className='btn btn-primary' onClick={Paynow}>Pay now</button>
                </div>
            </div>
        </div>
    )
}

const Checkout = () => {

    const [busy, setBusy] = React.useState(true);
    const [error, setError] = React.useState(true);
    const [jobSession, setjobSession] = React.useState([]);
    const { user, logout, BaseUrl } = React.useContext(UserContext);
    const param = useParams();

    const fetch_session = async () => {

        try {
            const { data, status } = await axios.get(`${BaseUrl}/student/job/checkout/${param.data}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            // console.log(data)
            if (data?.success && status === 200) {
                setjobSession(data.data);
            }

            if (status === 401) {
                logout();
            }

        } catch (error) {
            if (error?.response?.status === 401) {
                logout();
            }
            console.log(error)
        }

        setBusy(false);
    }


    React.useEffect(() => {
        fetch_session();
    }, []);

    return (
        <section className='Checkout-student card-list-box py-5 px-3 px-lg-0'>
            <div className='container-lg'>
                {error === 'funds' &&
                    <div className="alert d-flex justify-content-between align-items-center alert-danger col-12" role="alert">
                        You have insufficient funds.
                        <Link className='btn text-decoration-underline fw-medium' to="/Student/Wallet">Wallet</Link>
                    </div>
                }
                <div className='card pb-3'>
                    <div className='card-header bg-white py-3 py-lg-4 px-4'>
                        <h6 className='card-title mb-0 fw-medium'>Checkout</h6>

                    </div>
                    <div className='card-body py-0'>
                        {busy ?
                            <div className='p-5 text-center'>
                                <div className="spinner-border" role="status" style={{ color: '#074276' }}>
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            :
                            <>
                                {jobSession.length === 0 ?
                                    <p className='fs-5 my-2 p-3 text-center'>Somethings wents wrong</p>
                                    :
                                    <CheckoutCard jobSession={jobSession} setError={setError} />
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Checkout
