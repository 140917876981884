import axios from "axios";

class Auth {

    static user = '';
    static token = '';
    static error;

    static Signup = async (payload, BaseUrl) => {
        try {
            const { data } = await axios.post(`${BaseUrl}/user/register`, payload);
            return await data

        } catch (error) {
            console.log(error)
            return await error?.response;
        }
    }

    static login = async (payload, BaseUrl) => {
        try {
            const { data } = await axios.post(`${BaseUrl}/user/login`, payload);
            return await data

        } catch (error) {
            console.log(error)
            return await error?.response;
        }
    }

    static session = async (payload) => {
        try {
            const { data } = await axios.post(`${payload.BaseUrl}/user/session`, {}, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            });

            if (data?.success) {
                this.user = data.user;
                return true
            }

            this.error = data?.error;
            return false;

        } catch (error) {
            this.error = error;
            return false;
        }

    }
}

export default Auth;