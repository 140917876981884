import React from 'react'
import './AboutUs.css'
import NavBar from '../NavBar/NavBar'
import Tutors from '../Tutors/Tutors'
import Footer from '../Footer/Footer'

const AboutUs = () => {
  return (
    <div>
      <div className='about-us vh-100'>
        <div className='container-xxl'>
          <NavBar />
        </div>
        <div className='About-one'>
          About us
        </div>
      </div>
      <Tutors />
      <Footer />
    </div>
  )
}

export default AboutUs