import React from 'react';
import './css/tutoringdetails.css';
import axios from 'axios';
import { UserContext } from '../UserProvider';
import { Link, useNavigate, useParams } from 'react-router-dom';

const JobCard = ({ proposal }) => {

    const navigate = useNavigate();
    const param = useParams();
    const { user, logout, BaseUrl } = React.useContext(UserContext);

    const hadleSession = async (e) => {

        const button = e.currentTarget;
        button.disabled = true;

        try {
            const { data, status } = await axios.get(`${BaseUrl}/student/create/job/session/${param.data}`, {
                headers: {
                    'Authorization': `Bearer ${user?.token}`
                }
            });

            // console.log(data);



            if (data?.success && status === 200) {
                button.disabled = false;
                button.innerHTML = 'Approve';
                navigate(`/Student/Checkout/${data.token}`);
            }

            if (data?.success === false) {
                button.innerHTML = 'Try again';
            }

            if (status === 401) {
                logout();
            }

        } catch (error) {
            button.innerHTML = 'Try again';
            if (error?.response?.status === 401) {
                logout();
            }
            console.log(error)
        }

        button.disabled = false;
    }

    return (
        <div className='card'>
            <div className='card-header bg-white py-3 py-lg-4 px-4'>
                <h5 className='card-title fw-medium mb-0'>{proposal?.job?.title}</h5>
            </div>
            <div className='card-body'>
                <div className='message mt-1'>
                    <p className='fs-6 fw-medium ms-1 text-muted'>Message</p>
                    <div className='col-12 mb-5'>
                        <p className='m-0 col-md-10 p-2'>
                            {proposal.message}
                        </p>
                    </div>
                </div>
                <div className='d-flex justify-content-end align-items-center mt-3 gap-2'>
                    <Link type='button' to="/Student/Proposals" className='btn btn-primary'>Cancel</Link>
                    <button type='button' onClick={hadleSession} className='btn btn-primary'>Approve</button>
                </div>
            </div>
        </div>
    )
}


const UserCard = ({ user }) => {
    return (
        <div className='card'>
            <div className='card-body'>
                <div className='d-flex'>
                    <img src={user?.profile} height="140px" width="140px" alt='profile' className='profile' />
                </div>
                <div className='mt-4'>
                    <h4 className='user-name text-center'>{user?.first_name + ' ' + user?.last_name}</h4>
                </div>
            </div>
        </div>
    )
}

const ProposalDetails = ({ id }) => {

    const [busy, setBusy] = React.useState(true);
    const [proposal, setProposal] = React.useState([]);
    const { user, logout, BaseUrl } = React.useContext(UserContext);

    const FETCH_PROPOSAL = async () => {
        try {
            const { data, status } = await axios.get(`${BaseUrl}/student/proposal/${id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            if (data?.success && status === 200) {
                setProposal(data?.data[0]);
            }

            if (status === 401) {
                logout();
            }

        } catch (error) {
            if (error?.response?.status === 401) {
                logout();
            }
            console.log(error)
        }

        setBusy(false);
    }

    React.useEffect(() => {
        FETCH_PROPOSAL();
    }, []);

    if (proposal.length === 0 || busy) {
        return (
            <section className='TutoringDetails my-4 px-3 px-lg-0'>
                <div className='container-lg'>
                    <p className='ps-2 d-none d-md-block fw-medium'>{`Proposals > Details`}</p>
                    <div className='d-flex flex-wrap-reverse gap-md-3 gap-lg-5'>
                        <div className='card col'>
                            <div className='card-body text-center'>
                                {busy ?
                                    <div className='p-5 text-center'>
                                        <div className="spinner-border" role="status" style={{ color: '#074276' }}>
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    :
                                    <p className='fs-4 mb-0'>No Data Found</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <section className='TutoringDetails my-4 px-3 px-lg-0'>
            <div className='container-lg'>
                <p className='ps-2 d-none d-md-block fw-medium'>{`Proposals > Details`}</p>
                <div className='d-flex flex-wrap-reverse gap-md-3 gap-lg-5'>
                    <div className='col-12 col-md-8'>
                        <JobCard proposal={proposal} />
                    </div>
                    <div className='col'>
                        <UserCard user={proposal.user} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProposalDetails
