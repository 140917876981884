import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserContext } from "./UserProvider";
import TutorDashboard from "./tutor/Dashboard";
import StudentDashboard from "./student/Dashboard";
import Howitworks from "./Pages/Howitworks/Howitworks";
// Ensure case matches
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Home from "./Pages/Home/Home";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Contact from "./Pages/Contact/Contact";
import Verify from "./Pages/Verify";
import TermsCondition from "./Pages/TermsCondition";
import ForgetPassword from "./Pages/ForgetPassword";

const RoutesHandler = () => {
    const { user } = React.useContext(UserContext);
    // var Data = {};
    // const hasToken = localStorage.hasOwnProperty('user') ? true : false;
    // if(hasToken)
    // {
    //     Data = JSON.parse(localStorage.user)
    //     setUser(Data);
    // }
    
    const isLoggedIn = 'token' in user;

    const dashboardRoutes = [
        <Route
            key="tutor"
            path="Tutor/:page?/:data?"
            element={isLoggedIn ? <TutorDashboard /> : <Login />}
        />,
        <Route
            key="student"
            path="Student/:page?/:data?"
            element={isLoggedIn ? <StudentDashboard /> : <Login />}
        />,
    ];

    const loginRoutes = !isLoggedIn ? [
        <Route key="register" path="/Register" element={<Register />} />,
        <Route key="login" path="/Login" element={<Login />} />,
        <Route key="forgetPassword" path="/Forget-Password" element={<ForgetPassword />} />,

    ] : [];

    return (
        <Router>
            <Routes>
                <Route key="index" path="/" element={<Home />} />
                <Route key="aboutUs" path="/AboutUs" element={<AboutUs />} />
                <Route key="contact" path="/Contact" element={<Contact />} />
                <Route key="howitworks" path="/Howitworks" element={<Howitworks />} />
                <Route key="termsCondition" path="/TermsCondition" element={<TermsCondition />} />
                <Route key="verify" path="/register/verify/:token" element={<Verify />} />
                {dashboardRoutes}
                {loginRoutes}
            </Routes>
        </Router>
    );
};

export default RoutesHandler;
