import React from 'react';
import './Footer.css';
import Tutor from '../../Images/tutor.png';
import facebook from '../../Images/facebook.png';
import instagram from '../../Images/instagram.png';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import { Link } from 'react-router-dom';

const Footer = () => {
    let curYear = new Date();
    curYear = curYear.getFullYear();

    const disclaimerText = "No Guarantee of Results: Tutorscom does not guarantee any specific results or outcomes based on the use of its products, services, or information provided on this website. We respect your right to privacy and aim to ensure we comply with the legal requirements of the POPI Act, which regulates how we collect, process, store, share and destroy all personal information you provided to us.";

    return (
        <footer className='bg-primary mt-5'>
            <div className='container-xxl'>

                <div className='d-flex flex-wrap'>
                    <div className='col-12 col-md-6 py-3'>
                        <img src={Tutor} className='p-0' height="150px" width="auto" style={{ marginLeft: '-20px' }} alt='logo' />
                    </div>


                    <div className='col-12 col-md-6 pt-5'>
                        <h3 className='text-white'>Links</h3>
                        <div class="footer-links" style={{display:"flex"}}>

                        <div className='link-ho mt-3'>
                            <Link to="/"><h6 className='hom pb-2'>Home</h6></Link>
                            <Link to="/AboutUs"><h6 className='hom pb-2'>About us</h6></Link>
                            <Link to="/Howitworks"><h6 className='hom pb-2'>How It Works</h6></Link>
                            <Link to="/Contact"><h6 className='hom pb-2'>Contact us</h6></Link>
                            <Link to="/TermsCondition"><h6 className='hom pb-2'>TERMS AND CONDITIONS</h6></Link>
                        </div>

                        <div  className='link-ho marginLeft-md-8'>
                            <h3 className='text-white'>Info</h3>
                             <div style={{marginTop:"2rem"}}>
                             <h6 style={{fontSize:"18px" , color:"#fff"}}><LocalPhoneIcon/>+27 76 205 1513</h6>
                             <h6 style={{fontSize:"18px" , color:"#fff" , marginTop:"1rem"}}><EmailIcon/> admin@tutorscom.com</h6>
                             </div>
                        </div>

                        </div>


                        

                    </div>

                 



                    <div className='col pt-5'>
                        <div className='d-flex gap-3'>
                            <a href='https://www.facebook.com/profile.php?id=100094482605105&mibextid=LQQJ4d'><img src={facebook} alt="facebook" /></a>
                            <a href='https://instagram.com/tutorscommunityapp?igshid=OGQ5ZDc2ODk2ZA=='><img className="icon" src={instagram} alt="instagram" /></a>
                        </div>
                    </div>

                </div>


                <div>
                    <hr style={{ color: 'white' }} />
                    <div className="disclaimer">{disclaimerText}</div>
                    <div className='text-center py-3' style={{ color: 'white' }}>© Copyright {curYear} by Tutors Community</div>

                </div>




            </div>




        </footer>
    );
}

export default Footer;