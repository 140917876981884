import React from 'react';
import Howitsworks2 from '../../Images/Howitsworks2.jpeg';
import Howitsworks3 from '../../Images/Howitsworks3.jpeg';
import Howitsworks4 from '../../Images/Howitsworks4.jpeg';
import Howitsworks5 from '../../Images/Howitsworks5.jpg';

const HowitworksInfo = () => {
  const increasedHeadingStyle = {
    fontFamily: 'Poppins',
    fontSize: '2rem', // Adjust the font size as needed
    fontWeight: 'bold',
    marginBottom: '1rem',
  };

  return ( 
    <div className="container-xxl">
      <div className="fluid-institute" style={{ paddingBottom: '50px' }}>
        {/* First Section */}
        <div className="row mt-5 roww">
          <div className="col-12 col-md-6">
            {/* Section Title */}
            <h2 style={increasedHeadingStyle} className='mt-4'>How it works</h2>
            <ul style={{ padding: '0', textAlign: 'left' }}>
              {/* List of points */}
              <li>It is an online platform that can be accessed on our dedicated website.</li>
              <li className="mt-3">Students will use the platform to find a tutor within minutes.</li>
              <li className="mt-3">They will present the difficulty they face in the subject to the approved tutors and decide on the number of minutes to be spent in the session.</li>
              <li className="mt-3">Tutors will send their proposals, and the student will select a tutor they prefer.</li>
              <li className="mt-3">With this conversation, the student will know if the tutor is the right fit to assist them.</li>
              <li className="mt-3">Make payment for the tutoring session, with a rate of R5.00 per minute, as determined by the student. Please note that there is a minimum session duration of 5 minutes.</li>
              <li className="mt-3">The tutor will use a set of questions to determine the level of engagement needed to improve the student's understanding of the subject.</li>
              <li className="mt-3">The tutor will package a tutoring session based on that analysis using relevant study material and methods to clarify the area of study to the student.</li>
              <li className="mt-3">After the session is concluded, the student will declare the session complete, and the tutor will be remunerated for that session.</li>
              <li className="mt-3">The tutor can then cash-out and expect the funds to reach their pre-selected bank account within 5 working days.</li>
            </ul>
          </div>
          <div className="col-12 col-md-6">
            {/* Image */}
            <div className="des">
              <img src={Howitsworks2} alt="" className="book-one rounded-5 img-fluid" />
            </div>
          </div>
        </div>
        
        {/* Second Section */}
        <div className="row mt-5 pt-5 rowss">
          <div className="col-12 col-md-6">
            {/* Image */}
            <div className="desk">
              <img src={Howitsworks3} alt="" className="book-one rounded-5 img-fluid" />
            </div>
          </div>
          <div className="col-12 col-md-6">
            {/* Section Title */}
            <h2 style={increasedHeadingStyle}>This platform is designed for</h2>
            <ul style={{ padding: '0', textAlign: 'left' }}>
              {/* List of points */}
              <li>Educators, lecturers, or tutors who are already actively involved in teaching.</li>
              <li className="mt-3">Experts in specific study fields with in-depth knowledge and expertise.</li>
              <li className="mt-3">Individuals who have excelled in particular subjects and are eager to share their knowledge.</li>
            </ul>
            {/* Paragraph */}
            <p className="mt-4">
              Our platform caters to those who are dedicated to teaching and are committed to helping students grow and excel in their studies.
            </p>
          </div>
        </div>
        
        {/* Third Section */}
        <div className="row mt-5 roww">
          <div className="col-12 col-md-6">
            {/* Section Title */}
            <h2 style={increasedHeadingStyle}>Our platform is ideal for individuals who</h2>
            <ul style={{ padding: '0', textAlign: 'left' }}>
              {/* List of points */}
              <li>Seek to enhance their academic performance and achieve higher grades.</li>
              <li className="mt-3">Require supplementary classes to gain a deeper comprehension of a particular subject or module.</li>
              <li className="mt-3">Need support and guidance to overcome challenging areas of study.</li>
              <li className="mt-3">Occasionally need concise, detailed explanations to clarify specific concepts.</li>
            </ul>
          </div>
          <div className="col-12 col-md-6">
            {/* Image */}
            <div className="des">
              <img src={Howitsworks4} alt="" className="book-one rounded-5 img-fluid" />
            </div>
          </div>
        </div>
        
        {/* Fourth Section */}
        <div className="row mt-5 pt-5 rowss">
          <div className="col-12 col-md-6">
            {/* Image */}
            <div className="desk">
              <img src={Howitsworks5} alt="" className="book-one rounded-5 img-fluid" />
            </div>
          </div>
          <div className="col-12 col-md-6">
            {/* Section Title */}
            <h2 style={increasedHeadingStyle}>Tutoring sessions</h2>
            <ul style={{ padding: '0', textAlign: 'left' }}>
              {/* List of points */}
              <li>Are easy to access on the app or website.</li>
              <li className="mt-3">Are led by qualified pre-approved tutors.</li>
              <li className="mt-3">Are affordable.</li>
              <li className="mt-3">Are time used purposefully.</li>
              <li className="mt-3">The safety of both the student and the tutor is a high priority.</li>
              <li className="mt-3">The platform is user-friendly for both the student and tutor.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowitworksInfo;
