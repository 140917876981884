import axios from 'axios';
import React from 'react'
import Select from 'react-select';
import './css/requestLesson.css'
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserProvider';

const RequestForAdmin = ({ subjects }) => {

    const [inputs, setInputs] = React.useState({
        subjects_id: [], title: '', minutes: '', document: '', description: ''
    });
    const [rate, setRate] = React.useState(0);
    const [walletRate,setWalletRate] = React.useState(0);
    const button = React.useRef();
    const minsRef = React.useRef();
    const price = React.useRef();
    const fileInput = React.useRef();
    const selectRef = React.useRef();
    const { user, logout, BaseUrl } = React.useContext(UserContext);
    const [error, setError] = React.useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        let { name, value } = e.target;
        // console.log
        if (value.length === 1 && value.includes(' ')) {
            return
        }
          
        setInputs({ ...inputs, [name]: value });
    }

    const FETCH_HOURLY_RATE = async () => {
        try {
            button.current.disabled = true;
            minsRef.current.disabled = true;
            const { data, status } = await axios.get(`${BaseUrl}/hourly/rate`, {
                headers: {
                    'Authorization': `Bearer ${user?.token}`
                }
            });

            if (data?.success && status === 200) {
                setRate(data.data.price)
                minsRef.current.disabled = false;
            }

            if (data?.success === false) {
                setError('Somethings wents wrong try again')
            }

            if (status === 401) {
                logout();
            }

        } catch (error) {
            if (error?.response?.status === 401) {
                logout();
            }
            setError('Somethings wents wrong try again')
            console.log(error)
        }

        try {
            const { data, status } = await axios.get(`${BaseUrl}/student/wallet/details`, {
                headers: {
                    'Authorization': `Bearer ${user?.token}`
                }
            });

            if (data?.success && status === 200) {
                setWalletRate(data.data.amount)
                button.current.disabled = false;
            }

            if (status === 401) {
                logout();
            }

        } catch (error) {
            if (error?.response?.status === 401) {
                logout();
            }
            console.log(error)
        }
    }

    React.useEffect(() => {
        FETCH_HOURLY_RATE();
    }, []);

    const handlePrice = (e) => {
        let { name, value } = e.target;
        setInputs({ ...inputs, [name]: value });
        price.current.value = `R ${value * rate}`;
    }

    const handleSubmit = async (e) => {

        setError('');
        e.preventDefault();
        button.current.disabled = true;

        if (inputs.subjects_id.length === 0) {
            selectRef.current.focus();
            setError('Please select a subjects');
            button.current.disabled = false;
            return
        }

        if (inputs.description.trim().length < 20) {
            setError('Description must be greater than 20 letters.');
            button.current.disabled = false;
            return
        }
        if(parseInt(Object.values(price)[0].value.replace('R ','')) > parseInt(walletRate))
        {
            setError('Please Add Funds In Your Wallet. Your Current Balance is '+walletRate);
            button.current.disabled = false;
            return
        }
        const formData = new FormData();
        
        formData.append("subject_id", inputs.subjects_id.value);
        formData.append("title", inputs.title);
        formData.append("minutes", inputs.minutes);
        formData.append("description", inputs.description);

        if (fileInput.current.files.length > 0) {
            formData.append("document", fileInput.current.files[0], inputs.document);
        }

        try {
            const { data, status } = await axios.post(`${BaseUrl}/student/request-job-admin/store`, formData, {
                headers: {
                    'Authorization': `Bearer ${user?.token}`
                }
            });

            if (data?.success && status === 200) {
                button.current.innerHTML = 'Posted';
                navigate('/Student/Proposals');
            }

            if (data?.success === false) {
                button.current.innerHTML = 'Try again';
            }

            if (status === 401) {
                logout();
            }

        } catch (error) {
            button.current.innerHTML = 'Try again';
            if (error?.response?.status === 401) {
                logout();
            }
            console.log(error)
        }

        button.current.disabled = false;
    }

    const handleKeyPress = (event) => {
        if (event.key === 'e') {
            event.preventDefault();
        }
    };

    const handleDocuments = (e) => {

        const file = e.target.files[0];

        let { value } = e.target;

        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

        if (allowedTypes.includes(file.type) === false) {
            setInputs({ ...inputs, document: '' });
            e.target.classList.add('is-invalid');
            setError('Only PDF, Image, and Word files are allowed');
            return;
        }

        if (e.target.classList.contains('is-invalid')) {
            e.target.classList.remove('is-invalid');
        }

        setError('')
        setInputs({ ...inputs, document: value });
    }

    const getSubjectsOption = () => {
        const options = []

        for (let index = 0; index < subjects.length; index++) {
            const subject = subjects[index].subject;
            options.push({ value: subject.subject_id, label: subject.name });
        }

        return options;
    }

    const handleSelect = (selectedOptions) => {
        setInputs({ ...inputs, subjects_id: selectedOptions });
    };


    return (
        <section className='py-5 request-lesson'>
            <div className='container-lg'>
                <div className='card'>
                    <div className='card-header bg-white py-3 py-lg-4 px-4'>
                        <div className='d-flex align-items-center flex-wrap justify-content-between'>
                            <h6 className='card-title mb-0 fw-medium'>Seeking administrative assistance in identifying an ideal tutor.</h6>
                        </div>
                    </div>
                    <div className='card-body p-2'>
                        <form className='d-flex flex-wrap px-sm-2' autoComplete='off' onSubmit={handleSubmit}>
                            <div className='col-12 p-2'>
                                <label className={`col-form-label-sm ms-1 ${inputs?.subjects_id?.value > 0 ? 'opacity-1' : 'opacity-0'}`} htmlFor='select_subjects'>Select a subjects</label>
                                <Select
                                    isMulti={false}
                                    ref={selectRef}
                                    id='select_subjects'
                                    isDisabled={subjects.length === 0}
                                    options={subjects.length === 0 ? [] : getSubjectsOption()}
                                    value={inputs.subjects_id}
                                    onChange={handleSelect}
                                    className="form-control p-0 border-0"
                                    classNamePrefix="select"
                                    placeholder="Select a subjects"
                                />
                            </div>
                            <div className='col-12 p-2 '>
                                <label className={`col-form-label-sm ms-1 ${inputs?.title.trim().length > 0 ? 'opacity-1' : 'opacity-0'}`} htmlFor='title'>Title</label>
                                <input className='form-control' name='title' onChange={handleChange} value={inputs.title} placeholder='Title' type="text" required id='title' min={3}  pattern="^[a-zA-Z0-9_-]{3,16}$" />
                            </div>
                            <div className='col-12 col-sm-4 col-md-3 p-2'>
                                <label className={`col-form-label-sm ms-1 ${inputs?.minutes.trim().length > 0 ? 'opacity-1' : 'opacity-0'}`} htmlFor='minutes'>Required minutes</label>
                                <input className='form-control' name='minutes' ref={minsRef} min={5} id='minutes' onKeyPress={handleKeyPress} value={inputs?.minutes} onChange={handlePrice} placeholder='Required minutes' type="number" required />
                            </div>
                            <div className='col-12 col-sm-4 col-md-2 p-2'>
                                <label className={`col-form-label-sm ms-1 ${'opacity-0'}`} htmlFor='price'>Your Rand</label>
                                <input className='form-control disabled' name='price' id='price' ref={price} disabled placeholder='R ' type="text" />
                            </div>
                            <div className='col-12 col-sm-4 col-md-7 p-2'>
                                <label htmlFor="docFile" className={`col-form-label-sm ms-1 ${inputs?.document.length > 0 ? 'opacity-1' : 'opacity-0'}`}>
                                    Documents files
                                </label>
                                <input className="form-control" type="file" ref={fileInput} id="docFile" name='document' value={inputs?.document} onChange={handleDocuments} />
                            </div>
                            <div className='col-12 p-2'>
                                <label htmlFor="description" className={`col-form-label-sm ms-1 ${inputs?.description.trim().length > 0 ? 'opacity-1' : 'opacity-0'}`}>Description of challenges</label>
                                <textarea className="form-control" onChange={handleChange} value={inputs?.description} rows={8} placeholder="Description of challenges" name='description' id="description" required ></textarea>
                            </div>
                            <div className='col-12 d-flex align-items-center justify-content-end gap-2 mt-2 p-2'>
                                <h6 className='invalid-feedback d-flex ps-2 p-0 m-0'>{error}</h6>
                                <button className='btn btn-primary' type='submit' ref={button}>Post</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RequestForAdmin
