import React from 'react';
import NavBar from '../NavBar/NavBar';
import Tutors from '../Tutors/Tutors';
import Footer from '../Footer/Footer';
import HowitworksInfo from '../HowitworksInfo/HowitworksInfo';

const Howitworks= () => {
    return (
        <div>
        <div className='about-us vh-100'>
          <div className='container-xxl'>
            <NavBar />
          </div>
          <div className='About-one'>
           How It Works
          </div>
        </div>
        <HowitworksInfo /> 
        <Footer />
      </div>
    );
};

export default Howitworks;