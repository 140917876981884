import React, { useRef } from 'react';
import './css/tutoringdetails.css';
import axios from 'axios';
import { UserContext } from '../UserProvider';
import { Link, useNavigate } from 'react-router-dom';

const JobCard = ({ job, navigate }) => {

    const [msg, setMsg] = React.useState('');
    const button = useRef();
    const { user, logout, BaseUrl } = React.useContext(UserContext);


    const HandleSubmit = async (e) => {

        e.preventDefault();

        const payload = { message: msg, job_id: job?.id, }

        button.current.disabled = true;

        try {
            const { data, status } = await axios.post(`${BaseUrl}/tutor/proposals/create`, payload, {
                headers: {
                    'Authorization': `Bearer ${user?.token}`
                }
            });

            if (data?.success && status === 200) {
                navigate('/Tutor/Proposals');
            }

            if (status === 401) {
                logout();
            }

        } catch (error) {
            if (error?.response?.status === 401) {
                logout();
            }
            console.log(error)
        }

        button.current.disabled = false;
    }

    return (
        <div className='card'>
            <div className='card-header bg-white py-3 py-lg-4 px-4'>
                <h5 className='card-title fw-medium mb-0'>{job?.subject?.name}</h5>
            </div>
            <div className='card-body'>
                <div className='title ps-2'>
                    <p className='fs-5'>{job?.title}</p>
                </div>
                <div className='desc col-11 px-2'>
                    <p>{job?.description}</p>
                </div>
                {job?.file_url.trim().length > 0 &&
                    <div className='document col-11 px-2'>
                        <p>Document : <a href={job?.file_url} target="_document_files">click to download</a></p>
                    </div>
                }
                <form onSubmit={HandleSubmit}>
                    <div className='message mt-5'>
                        <p className='fs-6 fw-medium ms-1 text-muted'>Your Message</p>
                        <textarea className='col-12 border form-control' onChange={(e) => setMsg(e.target.value)} value={msg} rows={5} required></textarea>
                    </div>
                    <div className='d-flex justify-content-end align-items-center mt-3 gap-2'>
                        <Link type='button' to="/Tutor/Tutoring" className='btn btn-primary'>Cancel</Link>
                        <button className='btn btn-primary' ref={button}>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}


const UserCard = ({ user }) => {
    return (
        <div className='card'>
            <div className='card-body'>
                <div className='d-flex'>
                    <img src={user?.profile} height="140px" width="140px" alt='profile' className='profile' />
                </div>
                <div className='mt-4'>
                    <h4 className='user-name text-center'>{user?.first_name + ' ' + user?.last_name}</h4>
                </div>
            </div>
        </div>
    )
}

const TutoringDetails = ({ job_id }) => {

    const [busy, setBusy] = React.useState(true);
    const [job, setJob] = React.useState([]);
    const { user, logout, BaseUrl } = React.useContext(UserContext);
    const navigate = useNavigate();

    const FETCH_JOB = async () => {
        try {
            const { data, status } = await axios.get(`${BaseUrl}/tutor/job/fetch/id/${job_id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            if (data?.success && status === 200) {
                setJob(data.data[0])
            }

            if (status === 401) {
                logout();
            }

        } catch (error) {
            if (error?.response?.status === 401) {
                logout();
            }
            console.log(error)
        }

        setBusy(false);
    }

    React.useEffect(() => {
        FETCH_JOB();
    }, []);

    if (job.length === 0 || busy) {
        return (
            <section className='TutoringDetails mt-4 pt-5 px-3 px-lg-0'>
                <div className='container-lg'>
                    <p className='ps-2 d-none d-md-block fw-medium'>{`Available Tutoring > Details`}</p>
                    <div className='d-flex flex-wrap-reverse gap-md-3 gap-lg-5'>
                        <div className='card col'>
                            <div className='card-body text-center'>
                                {busy ?
                                    <div className='p-5 text-center'>
                                        <div className="spinner-border" role="status" style={{ color: '#074276' }}>
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    :
                                    <p className='fs-4 mb-0'>No Data Found</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <section className='TutoringDetails mt-4 pt-5 px-3 px-lg-0'>
            <div className='container-lg'>
                <p className='ps-2 d-none d-md-block fw-medium'>{`Available Tutoring > Details`}</p>
                <div className='d-flex flex-wrap-reverse gap-md-3 gap-lg-5'>
                    <div className='col-12 col-md-8'>
                        <JobCard job={job} navigate={navigate} />
                    </div>
                    <div className='col'>
                        <UserCard user={job.user} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TutoringDetails
