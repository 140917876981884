import React from "react";
import "./Community.css";
import cube from '../../Images/cube.png'
import review from '../../Images/review.png'
import calarity from '../../Images/calarity.png'

const Community = () => {
  return (
    <div className="container-xxl">
      <div className="fluid-community mt-5">
        <div className="community">
          <div><h1 style={{ color: "rgba(0,0,0,.78)", fontFamily: 'Poppins', fontWeight: '700' }}>Why choose us?</h1></div>
          <div className="pt-2" ><h3 style={{ color: '#000', fontFamily: 'Poppins', fontWeight: '400' }}>Benefits of being a Tutors’ Community member</h3></div>

          <div className="pt-3 text-center" >
            <p  style={{ color: 'rgba(0,0,0,.78);', lineHeight: '26px', fontFamily: 'Poppins', fontSize: '1rem', fontWeight: '400' }}>
              Here at Tutors Community we have  a lot of experienced tutors who did exceptionally well in their modules/ subjects. Tutors go through screening process before they can register on our platform. This process helps us in identifying tutors who can help students to understand their modules/subjects.
            </p>
          </div>

          <div className="d-flex justify-content-between pb-5 pt-2">
            <div className="col-12 col-lg-6">
              <div className="text-start w-100 d-flex flex-column gap-3 mt-4 p-2" style={{ color: 'rgba(0,0,0,.78)', lineHeight: '26px', fontFamily: 'Poppins', fontSize: '1rem', fontWeight: '300' }}>
                <li>Review and choose a tutor suitable for your study needs.</li>
                <li>Receive support and clarity on problematic subject areas in the comfort of your home.</li>
                <li>Tutoring classes are online; no need to travel.</li>
                <li>Personal assistance tailored according to the student/learner’s study needs.</li>
                <li>Sessions are affordable</li>
                <li>Good service and value for money.</li>
                <li>User-friendly and easy to access.</li>
                <li>Whiteboard enabled for a better experience.</li>
                <li>Chat/text message for quicker responses.</li>
              </div>
            </div>
            <div className="col-5 d-none d-lg-block">
              <div className="d-flex flex-column flex-wrap justify-content-center align-items-center mt-5 gap-3">
                <div className="col-12 d-flex justify-content-center align-items-center gap-4">
                  <div className="text-center">
                    <img src={cube} alt="" />
                    <p style={{ fontWeight: "800", fontFamily: 'Poppins' }} className="pt-2" >100+ Modules</p>
                    {/* <p style={{ fontWeight: '400', fontFamily: 'Poppins' }} >
                      Lorem ipsum dolor <br /> sit amet consectetur adipisicing.
                    </p> */}
                  </div>
                  <div className="col d-none d-md-block">
                    <hr />
                  </div>
                  <div className="text-center">
                    <img src={review} alt="" />
                    <p style={{ fontWeight: "800", fontFamily: 'Poppins' }} className="pt-2" >100+ Modules</p>
                    {/* <p style={{ fontWeight: '400', fontFamily: 'Poppins' }} >
                      Lorem ipsum dolor <br /> sit amet consectetur adipisicing.
                    </p> */}
                  </div>
                </div>
                {/* <div className="col d-none d-md-block">
                  <hr />
                </div> */}
                <div className="text-center">
                  <img src={calarity} alt="" />
                  <p style={{ fontWeight: "800", fontFamily: 'Poppins' }} className="pt-2" >100+ Modules</p>
                  {/* <p style={{ fontWeight: '400', fontFamily: 'Poppins' }} >
                    Lorem ipsum dolor <br /> sit amet consectetur adipisicing.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="d-flex flex-wrap justify-content-center align-items-center mt-5 gap-5">
          <div className="text-center col-12 col-md-3">
            <img src={cube} alt="" />
            <p style={{ fontWeight: "800", fontFamily: 'Poppins' }} className="pt-2" >100+ Modules</p>
            <p style={{ fontWeight: '400', fontFamily: 'Poppins' }} >
              Lorem ipsum dolor <br /> sit amet consectetur adipisicing.
            </p>
          </div>
          <div className="col d-none d-md-block">
            <hr />
          </div>
          <div className="text-center col-12 col-md-3">
            <img src={review} alt="" />
            <p style={{ fontWeight: "800", fontFamily: 'Poppins' }} className="pt-2" >100+ Modules</p>
            <p style={{ fontWeight: '400', fontFamily: 'Poppins' }} >
              Lorem ipsum dolor <br /> sit amet consectetur adipisicing.
            </p>
          </div>
          <div className="col d-none d-md-block">
            <hr />
          </div>
          <div className="text-center col-12 col-md-3">
            <img src={calarity} alt="" />
            <p style={{ fontWeight: "800", fontFamily: 'Poppins' }} className="pt-2" >100+ Modules</p>
            <p style={{ fontWeight: '400', fontFamily: 'Poppins' }} >
              Lorem ipsum dolor <br /> sit amet consectetur adipisicing.
            </p>
          </div>
        </div> */}

        {/* <div className="row   py-5  card-one mt-3 ">
          <div className="col-lg-5 col-md-6 Modules" style={{ position: 'relative' }}>
            <div className="cube">
              <img src={cube} alt="" />
              <p style={{ fontWeight: "800", fontFamily: 'Poppins' }} className="pt-2" >100+ Modules</p>
              <p style={{ marginTop: '-10%', paddingTop: '0%', fontWeight: '400', fontFamily: 'Poppins' }} >
                Lorem ipsum dolor sit amet consectetur adipisicing.
              </p>
            </div>
            <hr className="ac" style={{ width: '65%', marginLeft: '150px', Top: '0', marginTop: '-180px', position: 'absolute' }} />
          </div>

          <div className="col-md-6 col-lg-5 Modules" style={{ position: 'relative' }}>
            <div className="cube">
              <img src={review} alt="" style={{ width: '146px', height: '134px' }} className="im" />
              <p style={{ fontWeight: "800", fontFamily: 'Poppins' }} className="pt-2" >Review</p>
              <p style={{ marginTop: '-10%', paddingTop: '0%', fontWeight: '400', fontFamily: 'Poppins' }} >
                Lorem ipsum dolor sit amet consectetur adipisicing.
              </p>
            </div>
            <hr className="ab" style={{ width: '65%', marginLeft: '150px', Top: '0', marginTop: '-180px', position: 'absolute' }} />
          </div>
          <div className="col-lg-2 col-md-12 Modules" style={{ position: 'relative' }}>
            <div className="cube">
              <img src={calarity} alt="" style={{ width: '146px', height: '134px' }} />
              <p style={{ fontWeight: "800", fontFamily: 'Poppins' }} className="pt-2" >100+ Modules</p>
              <p style={{ marginTop: '-10%', paddingTop: '0%', fontFamily: 'Poppins' }} >
                Lorem ipsum dolor sit amet consectetur adipisicing.
              </p>
            </div>
          </div>
        </div> */}

      </div>
    </div>
  );
};

export default Community;
