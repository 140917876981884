import React from 'react'
import './Contact.css'
import NavBar from '../NavBar/NavBar'
import ContactForm from '../ContactForm/ContactForm'
import Footer from '../Footer/Footer'

const Contact = () => {
  return (
    <div>
      <div className='contact vh-100'>
        <div className='container-xxl'>
          <NavBar />
        </div>
        <div className='About-one'>
          Contact us
        </div>

      </div>

      <ContactForm />
      <Footer />
    </div>
  )
}

export default Contact