import React from 'react'
import './ContactForm.css'
import axios from 'axios'
import { UserContext } from '../../UserProvider'

const ContactForm = () => {

    const [data, setData] = React.useState({ name: '', email: '', message: '' });
    const [error, setError] = React.useState('');
    const { BaseUrl } = React.useContext(UserContext);
    const button = React.useRef();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value })
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        button.current.disabled = true;

        const { name, email, message } = data;

        const payload = {
            'name': name,
            'email': email,
            'message': message
        }

       try {
    const { data } = await axios.post(`${BaseUrl}/contact/details`, payload);

    if (data?.success) {
        setError('The message was submitted successfully, We will get back to you soon.');
        setData({ name: '', email: '', message: '' })
    }
    else {
        setError('Message submitted unsuccessfully.');
    }

} catch (error) {
    console.log(error)
    return await error?.response;
}

        button.current.disabled = false;
    }

    return (
        <div className='contact-for container-xxl'>

            <div className='row pt-5 contact-rows'>
                <div className='col-5  col-five' >
                    <h1 className='conta mt-4'>
                        We are always ready  <br />to hear from you
                    </h1>
                    <div className='contact-info-container'>
                        <div className='contact-info'>
                       <h5>Phone : +27 76 205 1513</h5>
                       <h5>Email: admin@tutorscom.com</h5>
                        </div>
                    </div>
              
                    
                </div>

                <form className='col-7 px-5 pb-4 col-seven' onSubmit={handleSubmit}>
                    {error.length > 0 &&
                        <div className='mx-3 alert alert-primary fs-6'>{error}</div>
                    }
                    <div className='contact-row pt-4'>
                        <input type='text' required style={{ width: '100%' }} name='name' value={data.name} className='mx-3 inp-color py-3 nam' placeholder='Your Name' onChange={handleChange} />
                        <input type='email' required style={{ width: '100%' }} name='email' value={data.email} className='mx-3 inp-color py-3' placeholder='Email' onChange={handleChange} />
                    </div>
                    <div className='text-area pt-5'>
                        <textarea required style={{ width: '100%', height: '30vh' }} className='mx-3 text-inp' placeholder='Write Message' onChange={handleChange} value={data.message} name='message'></textarea>
                    </div>
                    <div className='text-center pt-5 pb-5'>
                        <button type='submit' className='Submit-btn py-2' ref={button}>Submit</button>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default ContactForm