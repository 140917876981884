import React from 'react'
import './css/proposal.css';
import { UserContext } from '../UserProvider';
import axios from 'axios';
import { Link } from 'react-router-dom';

const JobCard = ({ proposals, setProposals }) => {

    const { user, logout, BaseUrl, AppUrl } = React.useContext(UserContext);

    const handleDelete = async (e, index) => {
        const element = e.currentTarget;
        const id = element.getAttribute('data');
        element.disabled = true;

        try {
            const { data, status } = await axios.get(`${BaseUrl}/tutor/proposals/delete/${id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            if (data?.success && status === 200) {
                setProposals(prev => prev.filter((_, i) => i !== index));
                element.disabled = false;
                return;
            }

            element.innerHTML = 'Try again';

            if (status === 401) {
                logout();
            }

        } catch (error) {
            element.innerHTML = 'Try again';
            if (error?.response?.status === 401) {
                logout();
            }
            console.log(error)
        }

        element.disabled = false;
    }

    function printStringWithLength(str, length) {
        if (str.length >= length) {
            return str.slice(0, length) + ' ...';
        }
        return str.padEnd(length, ' ');
    }

    function getTimeAndDate(timestamp) {
        const date = new Date(timestamp);
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const day = String(date.getDate()).padStart(2, '0');
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }

    return (
        proposals.map((proposal, index) => (

            <div className='card-list d-flex flex-wrap justify-content-between border-bottom py-4' key={index}>
                <div className='user-info-box d-flex gap-3'>
                    <img src={proposal?.jobs?.user?.profile} height="50px" width="50px" className='rounded-circle' alt='profile' />
                    <div className='user-info'>
                        <h6 className='fw-bold'>{proposal?.jobs?.user?.first_name + ' ' + proposal?.jobs?.user?.last_name}</h6>
                        <h6>{getTimeAndDate(proposal?.created_at)}</h6>
                    </div>
                </div>

                <div className='subject-info'>
                    <h6 className='fw-bold'>{printStringWithLength(proposal?.jobs?.subject.name, 20)}</h6>
                    <h6>{printStringWithLength(proposal?.jobs?.title, 20)}</h6>
                </div>

                <div className='price-info'>
                    <h6 className='fw-bold'>Price</h6>
                    <h6>R {proposal?.jobs?.price}</h6>
                </div>

                <div className='status-info'>
                    <h6 className='fw-bold'>Status</h6>
                    <h6>{proposal?.status === 'pending' ? 'Delivered' : proposal?.status}</h6>
                </div>

                <div className='action-btn d-flex align-items-center justify-content-end gap-2'>
                    <Link type='button' to={`/Tutor/Proposals/${proposal?.id}`} className='btn btn-primary'>DETAILS</Link>
                    {proposal?.status === 'accepted' && proposal?.jobs?.status === 'progress' &&
                        <>
                            {/* <Link to={`${AppUrl}/tutor/message/${proposal?.jobs?.session?.token}?token=${user.token}`} target="_blank" className='btn btn-primary' type='button'>CHAT</Link> */}
                            <Link to={`${AppUrl}/tutor/meet/${proposal?.jobs?.session?.token}?token=${user.token}`} target="_blank" className='btn btn-primary' type='button'>WHITEBOARD</Link>
                        </>
                    }

                    {(proposal?.status === 'accepted' && proposal?.jobs?.status !== 'progress') &&
                        <button className='btn btn-primary' disabled>Completed</button>
                    }

                    {proposal?.status !== 'accepted' &&
                        <button className='btn btn-primary' data={proposal?.id} onClick={(e) => handleDelete(e, index)}>DELETE</button>
                    }
                </div>

            </div>
        ))
    )
}

const Proposal = () => {

    const [busy, setBusy] = React.useState(true);
    const [proposals, setProposals] = React.useState([]);
    const { user, logout, BaseUrl } = React.useContext(UserContext);

    React.useEffect(() => {
        FETCH_PROPOSALS()
    }, []);


    const FETCH_PROPOSALS = async () => {
        try {
            const { data, status } = await axios.get(`${BaseUrl}/tutor/proposals/fetch/all`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            if (data?.success && status === 200) {
                setProposals(data.data);
            }

            if (status === 401) {
                logout();
            }

        } catch (error) {
            if (error?.response?.status === 401) {
                logout();
            }
            console.log(error)
        }

        setBusy(false);
    }

    return (
        <section className='Proposal card-list-box d-flex justify-content-center align-items-center mt-5 px-3 px-lg-0'>
            <div className='card container-lg'>
                <div className='card-header bg-white py-3 py-lg-4 px-4'>
                    <h6 className='card-title mb-0 fw-medium'>Proposals</h6>

                </div>
                <div className='card-body py-0'>
                    {busy ?
                        <div className='p-5 text-center'>
                            <div className="spinner-border" role="status" style={{ color: '#074276' }}>
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        :
                        <div className='card-body py-0'>
                            {proposals.length === 0 ?
                                <p className='fs-5 p-3 text-center'>No Proposals Found</p>
                                :
                                <JobCard proposals={proposals} setProposals={setProposals} />
                            }
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}

export default Proposal
